import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { VisitListener } from '@backstage/plugin-home';

import { googleAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import { UnifiedThemeProvider } from '@backstage/theme';
import { axelerantLightTheme } from './theme/axelerantLight';
import { axelerantDarkTheme } from './theme/axelerantDark';
import { CssBaseline } from '@material-ui/core';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { AxelerantTechnologiesPage } from '@internal/plugin-axelerant-technologies';
import { AxelerantDrupalInsightPage } from '@internal/plugin-axelerant-drupal-insight';

import * as Sentry from '@sentry/react';
import { CaptureConsole, HttpClient } from '@sentry/integrations';
import { ConfigReader, defaultConfigLoader } from '@backstage/core-app-api';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { NewRelicPage } from '@backstage-community/plugin-newrelic';

import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { ProxyOverridePage } from '@internal/plugin-proxy-override';
// import { apertureTheme } from './theme/apertureTheme';
import { modifyFetch } from './modifyFetch';
import { HomePageEntityCardsPage } from '@internal/plugin-home-page-entity-cards';
import { OnepasswordVaultManagerPage } from '@internal/backstage-plugin-onepassword-vault-manager';
import { TechDocPrintButtonAddon } from '@internal/backstage-plugin-techdoc-addons';
import { PlatformshPage } from '@axelerant/backstage-plugin-platformsh';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { SignalsDisplay } from '@backstage/plugin-signals';
import { onepasswordVaultManagerPermission } from '@internal/backstage-plugin-onepassword-vault-manager-common';

const app = createApp({
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'google-auth-provider',
            title: 'Login',
            message: 'Sign in using Google',
            apiRef: googleAuthApiRef,
          }}
        />
      );
    },
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'axelerant-light',
      title: 'Light Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={axelerantLightTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'axelerant-dark',
      title: 'Dark Theme',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={axelerantDarkTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    // {
    //   id: 'aperture',
    //   title: 'aperture',
    //   variant: 'light',
    //   Provider: ({ children }) => (
    //     <UnifiedThemeProvider theme={apertureTheme} noCssBaseline>
    //       <CssBaseline />
    //       {children}
    //     </UnifiedThemeProvider>
    //   ),
    // },
    // {
    //   id: 'light',
    //   title: 'Light',
    //   variant: 'light',
    //   Provider: ({ children }) => (
    //     <UnifiedThemeProvider theme={themes.light} children={children} />
    //   ),
    // },
    // {
    //   id: 'dark',
    //   title: 'Dark',
    //   variant: 'dark',
    //   Provider: ({ children }) => (
    //     <UnifiedThemeProvider theme={themes.dark} children={children} />
    //   ),
    // },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    {/* <Route path="/" element={<Navigate to="catalog" />} /> */}
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <TechDocPrintButtonAddon />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/axelerant-technologies"
      element={<AxelerantTechnologiesPage />}
    />
    <Route
      path="/axelerant-drupal-insight"
      element={<AxelerantDrupalInsightPage />}
    />
    <Route path="/newrelic" element={<NewRelicPage />} />
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/proxy-override" element={<ProxyOverridePage />} />
    <Route
      path="/home-page-entity-cards"
      element={<HomePageEntityCardsPage />}
    />
    <Route
      path="/onepassword-vault-manager"
      element={
        <RequirePermission permission={onepasswordVaultManagerPermission}>
          <OnepasswordVaultManagerPage />
        </RequirePermission>
      }
    />
    <Route path="/platformsh" element={<PlatformshPage />} />;
    <Route path="/announcements" element={<AnnouncementsPage hideInactive />} />
  </FlatRoutes>
);

// Sentry logging.
if (process.env.NODE_ENV !== 'test') {
  const config = ConfigReader.fromConfigs(await defaultConfigLoader());
  const sentryLoggerConfig = config.getOptionalConfig('logging.sentry');
  if (sentryLoggerConfig && sentryLoggerConfig.getBoolean('enabled')) {
    Sentry.init({
      dsn: sentryLoggerConfig.getString('dsn'),
      integrations: [
        new Sentry.BrowserTracing({
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        // new Sentry.Replay(),
        // new CaptureConsole(),
        new CaptureConsole({ levels: ['warn', 'error'] }),
        new HttpClient(),
      ],
      tracesSampleRate: 1.0,
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1.0,
      initialScope: {
        tags: { apptype: 'app' },
      },
      environment: sentryLoggerConfig.getString('environment'),
    });
  }
  modifyFetch();
}

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <SignalsDisplay />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
